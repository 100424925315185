import React from "react"
import pr from "src/assets/images/pr.jpg"
import niepodlegla from "src/assets/images/kul.jpg"
import pr2 from "src/assets/images/pr2.svg"
import fina from "src/assets/images/fina.svg"
import pilsudski from "src/assets/images/pilsudski.svg"
import bud from "src/assets/images/budPL.jpg"

import niepodlegla2 from "src/assets/images/niepodlegla2.svg"
import { isMobile } from "react-device-detect"
import styled from "styled-components"

const CreditsBody = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f5f4ee;
  padding-left: 8rem;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  ${isMobile &&
    `
      padding-left: 0;
    `}
  @media (max-width: 1700px) {
    padding-top: 70px;
  }
`

const CreditsContainer = styled.div`
  display: flex;
  height: 90%;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 7rem;
  ${isMobile &&
    `
    height: 100%;
    flex-direction: column;
    padding: 2rem;
  `}
  @media (max-width: 1700px) {
    padding: 0 6rem;
  }
  @media (max-width: 768px) {
    padding: 2rem;
  }
`

const CreditsSeparator = styled.div`
  position: relative;
  top: 8%;
  height: 65%;
  border: 1px solid rgba(38, 34, 34, 0.2);
  border-left: none;
  margin: 0 3rem;
  ${isMobile &&
    `
    display: none;
  `}
  @media (max-width: 1700px) {
    height: 60%;
  }
`

const CreditsColumn = styled.div`
  width: 100%;
  ${isMobile &&
    `
    width: 100%;
  `}
`

const CreditsCategory = styled.h2`
  font-family: "Red Hat Display", "Open Sans";
  color: #df3242;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  @media (max-width: 1700px) {
    margin-bottom: 0.3rem;
    font-size: 0.7rem;
  }
`

const CreditsItem = styled.div`
  font-family: "Red Hat Display", "Open Sans";
  font-size: 0.9rem;
  margin-bottom: 1.2rem;
  line-height: 1.6;
  @media (max-width: 1700px) {
    margin-bottom: 1rem;
  }
`

const CreditsLogos = styled.div`
  display: flex;
  align-items: flex-start;
  ${isMobile &&
    `
      flex-direction: column;
      align-items: center;
      `}
`

const CreditsLogos2 = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.5rem;
`

const CreditsLogo = styled.img`
  width: 7rem;
  margin-right: 2.625rem;
  ${isMobile &&
    `
        width: 80%;
      `}
`

const PartnersLogo = styled.img`
  margin-right: 2.625rem;
  ${isMobile &&
    `
        margin-right: 0;
        margin-bottom: 2rem;
      `}
`

const Credits = () => (
  <CreditsBody>
    <CreditsContainer>
      <CreditsColumn>
        <CreditsLogos2>
          <a href="https://www.polskieradio.pl/" target="_blank">
            <CreditsLogo src={pr2} alt="Polskie Radio" />
          </a>
          <a href="https://niepodlegla.gov.pl/" target="_blank">
            <CreditsLogo src={niepodlegla2} alt="Niepodlegla" />
          </a>
        </CreditsLogos2>
        <CreditsCategory tabIndex="0">
          Koncepcja, treści, pozyskiwanie materiałów audiowizualnych
        </CreditsCategory>
        <CreditsItem tabIndex="0">
          <p>Izabella Mazurek, Bartłomiej Makowski, Krzysztof Kossowski</p>
          <p>
            Specjalne podziękowania dla prof. Grzegorza Nowika za nadzór
            merytoryczny
          </p>
        </CreditsItem>
        <CreditsCategory tabIndex="0">
          Kreacja i projektowanie graficzne
        </CreditsCategory>
        <CreditsItem tabIndex="0">Łukasz Haponiuk, Paweł Woźniak</CreditsItem>
        <CreditsCategory tabIndex="0">Zespół Developerski</CreditsCategory>
        <CreditsItem tabIndex="0">
          Piotr Gajcy, Łukasz Kowalski, Bartosz Miller, Michał Romańczuk,
          Mateusz Orłowski, Piotr Dul
        </CreditsItem>
        <CreditsCategory tabIndex="0">
          Koordynacja Projektu
        </CreditsCategory>
        <CreditsItem tabIndex="0">
          Grzegorz Kowalski, Piotr Gajcy, Małgorzata Dubel
        </CreditsItem>
        <CreditsCategory tabIndex="0">
          Testy i Wsparcie Techniczne
        </CreditsCategory>
        <CreditsItem tabIndex="0">
          Alan Krawczyk, Mariusz Łapiński, Paula Karolak
        </CreditsItem>
        <CreditsCategory tabIndex="0">Intro</CreditsCategory>
        <CreditsItem tabIndex="0">
          Bartosz Tytus Trojanowski (animacja), Łukasz Haponiuk (postprodukcja i
          udźwiękowienie), Jan Aleksandrowicz-Krasko (lektor), Bartłomiej
          Makowski (scenariusz)
        </CreditsItem>
        <CreditsCategory tabIndex="0">MONTAŻ AuDIOTRANSKRYPCJI</CreditsCategory>
        <CreditsItem tabIndex="0">
          Dział Produkcji Multimedialnych Polskiego Radia
        </CreditsItem>
        <CreditsCategory tabIndex="0">PARTNERZY</CreditsCategory>
        <CreditsItem tabIndex="0">
          Paulina Łukawska, Anna Bińkowska, Marcin Rembacz
        </CreditsItem>
        <CreditsCategory tabIndex="0">Tłumaczenia</CreditsCategory>
        <CreditsItem tabIndex="0">
          Instytut Polski w Budapeszcie, Barry Keane, Biuro Tłumaczeń Lidex
        </CreditsItem>
      </CreditsColumn>
      <CreditsSeparator />
      <CreditsColumn>
        <CreditsCategory tabIndex="0">
          Fotografie pochodzą ze zbiorów
        </CreditsCategory>
        <CreditsItem tabIndex="0">
          Narodowe Archiwum Cyfrowe, Polska Agencja Prasowa, Biblioteka Narodowa
          (Polona.pl), Centralne Archiwum Wojskowe, East News – agencja
          fotograficzna; Biblioteka Kongresu; National Archives of Records
          Administration; Wikimedia Commons (domena publiczna); Europeana.eu,
          National Library of Scotland;
        </CreditsItem>
        <CreditsCategory tabIndex="0">
          Materiały dźwiękowe pochodzą ze zbiorów
        </CreditsCategory>
        <CreditsItem tabIndex="0">
          Archiwum Polskiego Radia, Archiwum Rozgłośni Polskiej Radia Wolna
          Europa
        </CreditsItem>
        <CreditsCategory tabIndex="0">
          W serwisie wykorzystano materiały filmowe
        </CreditsCategory>
        <CreditsItem tabIndex="0">
          <p>
            „Dla Ciebie Polsko”, reż. Antoni Bednarczyk, Filmoteka Narodowa –
            Instytut Audiowizualny;
          </p>
          <p>
            „Sztandar Wolności”, reż. Ryszard Ordyński, Filmoteka Narodowa –
            Instytut Audiowizualny;
          </p>
          <p>
            „Scenes of the Russian Revolution”; National Archives and Records
            Administration;
          </p>
          <p>
            „Partition of Poland”; National Archives and Records Administration;
          </p>
          <p>
            „Selected newsreel scenes on Russian satellite countries, Europe”;
            National Archives of Records Administration;
          </p>
          <p>„Weeknummer 22-08”; Openbeelden.nl /via Europeana.eu</p>
          <p>
            „Women Troops For Polish / Russian War Aka Poland's Army Of Amazons
            1920”; British Pathé
          </p>
        </CreditsItem>
        <CreditsCategory tabIndex="0">WSPÓŁPRACA</CreditsCategory>
        <CreditsItem tabIndex="0">
          <p>
            Filmoteka Narodowa – Instytut Audiowizualny, Muzeum Józefa
            Piłsudskiego w Sulejówku
          </p>
          <br />
          <p>
            Projekt realizowany w ramach obchodów stulecia odzyskania
            niepodległości oraz odbudowy polskiej państwowości.
          </p>
        </CreditsItem>
        <CreditsLogos>
          <PartnersLogo
            src={niepodlegla}
            style={{ width: "6rem" }}
            alt="Ministerstwo Kultury i Dziedzictwa Narodowego"
          />
          <PartnersLogo
            src={pr}
            style={{ width: "7rem" }}
            alt="Polskie Radio"
          />
          <PartnersLogo
            src={pilsudski}
            style={{ width: "8rem" }}
            alt="Muzeum Józefa Piłsudskiego w Sulejówku"
          />
          <PartnersLogo src={fina} style={{ width: "4rem" }} alt="FINA" />
          <PartnersLogo src={bud} style={{ width: "6rem" }} alt="IPB" />
        </CreditsLogos>
      </CreditsColumn>
    </CreditsContainer>
  </CreditsBody>
)

export default Credits
